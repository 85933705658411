@fontFamily: PlayfairDisplay-MediumItalic;
.content {
  padding: 30px 24px;
  max-width: 1060px;
  margin: 50px auto 0;
  .topContainer {
    text-align: center;

    h1,
    h2 {
      font-size: 32px;
      font-weight: bold;
      color: var(--app-text-color);
      font-family: @fontFamily;
    }

    p {
      font-size: 16px;
      margin-top: 24px;
      color: var(--app-text-60-color);
    }

    p:last-child {
      font-size: 16px;
      margin-top: 24px;
      color: var(--app-text-color);
      font-weight: bold;
    }
  }

  .collapseContent {
    margin-top: 42px;

    .infoContainer {
      color: var(--app-text-60-color);

      p {
        margin-bottom: 10px;
      }
      a {
        color: var(--app-primary-color);
      }
    }
  }
  :global {
    .next-antd-collapse-borderless {
      background-color: rgba(0, 0, 0, 0);
    }

    .next-antd-collapse-expand-icon,
    .next-antd-collapse-header-text {
      color: var(--app-text-color);
    }

    .next-antd-collapse-content-box {
      color: var(--app-text-color);
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    // padding: 0;
  }
}

